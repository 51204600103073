<template>
    <b-row class="py-2" no-gutters>
        <b-col md="12">
            <h4><slot></slot></h4>
        </b-col>
    </b-row>
</template>

<script>
export default {
    name: 'text-header',
};
</script>
