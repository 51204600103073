<template>
    <div v-if="purchase">
        <h4 class="font-weight-bold">Прямая закупка № {{ purchase.registration_number }} ({{ purchase.status.title }})</h4>
        <h5 class="font-weight-bold">
            На основании несостоявшегося конкурса №
            <router-link class="system_link-style3" :to="{ name: 'CompetitivePurchaseShow', params: { id: purchase.failed_purchase.id } }" target="_blank">{{ purchase.failed_purchase.registration_number }}</router-link>
        </h5>

        <loading-card v-show="loading"></loading-card>

        <b-tabs v-if="!loading">
            <b-tab title="Общая информация" active>
                <b-card>
                    <text-header>Информация о заказчике</text-header>
                    <text-row-link label="Наименование" v-model="purchase.customer.name" :router-link="{ name: 'OrganizationEdit', params: { id: purchase.customer.id } }" />
                    <text-row label="ИНН" v-model="purchase.customer.inn" />
                    <text-row label="Государственный регистрационный номер" v-model="purchase.customer.reg_number" />
                    <text-row label="ОКПО" v-model="purchase.customer.okpo" />
                    <text-row label="Юридический адрес" v-model="purchase.customer.address" />
                    <text-row label="Дополнительная контактная информация" v-model="purchase.additional_contact_info" v-if="purchase.additional_contact_info" />
                    <hr />
                    <text-header>Условия закупки</text-header>
                    <text-row label="Наименование конкурса" v-model="purchase.name" v-if="purchase.name" />
                    <text-row label="Предмет закупки" v-model="purchase.deliverable_group_title" />
                    <text-row-datetime label="Дата и время размещения закупки" v-model="purchase.purchase_start_datetime" />
                    <text-row-datetime label="Дата и время окончания приема заявок и вскрытия конвертов" v-model="purchase.purchase_end_datetime" />
                    <text-row label="Формат заключения контракта" v-model="purchase.edms.contract_format_title" />
                    <text-row label="Оператор ЭДО" v-model="purchase.edms.operator_name" v-if="purchase.edms.operator_name" />
                    <text-row-price label="Сумма закупки" v-model="purchase.starting_price" />
                    <text-row label="Валюта" v-model="purchase.currency_title" />
                    <text-row-document label="Проект контракта" v-model="purchase.contract_draft_attachment" v-if="purchase.contract_draft_attachment" />
                    <text-row-documents label="Дополнительные документы" v-model="purchase.notice_supplementary_attachments" v-if="purchase.notice_supplementary_attachments && purchase.notice_supplementary_attachments.length > 0" />
                    <text-header>Требования к поставщикам</text-header>
                    <ul>
                        <li class="fs-14 grey-color my-auto col-form-label" v-if="purchase.proposal_validity_days">Минимальное количество дней действия предложения участника - {{ purchase.proposal_validity_days }}</li>
                        <li class="fs-14 grey-color my-auto col-form-label">
                            У участника закупочной сессии должны отсутствовать задолженности по налогам и сборам -
                            {{ purchase.supplier_requirements.has_no_arrears ? 'Да' : 'Нет' }}
                        </li>
                        <li class="fs-14 grey-color my-auto col-form-label">
                            Участник закупки не должен присутствовать в реестре недобросовестных поставщиков -
                            {{ purchase.supplier_requirements.is_reliable_supplier ? 'Да' : 'Нет' }}
                        </li>
                        <template v-if="purchase.supplier_requirements.has_additional_requirements && purchase.supplier_requirements.additional_requirements && purchase.supplier_requirements.additional_requirements.length > 0">
                            <li class="fs-14 grey-color my-auto col-form-label">
                                Участник закупки должен соответствовать следующим требованиям -
                                {{ purchase.supplier_requirements.additional_requirements_description }}
                            </li>
                            <li class="fs-14 grey-color my-auto col-form-label">Участник закупки должен подтвердить соответствие указанным требованиям, прикрепив следующие документы при подаче заявки:</li>
                            <li style="list-style-type: none">
                                <ul>
                                    <li class="fs-14 grey-color my-auto col-form-label" v-for="requiredDocument in purchase.supplier_requirements.additional_requirements" :key="requiredDocument.id">
                                        {{ requiredDocument.title }}
                                    </li>
                                </ul>
                            </li>
                        </template>

                        <li class="fs-14 grey-color my-auto col-form-label">
                            Участник закупки должен обладать опытом аналогичных поставок на сумму не менее -
                            {{ purchase.supplier_requirements.similar_experience_amounts ? $formatPrice(purchase.supplier_requirements.similar_experience_amounts) : 'Не требуется' }}
                        </li>
                    </ul>

                    <text-header>Спецификация</text-header>
                    <b-table-simple responsive>
                        <b-thead head-variant="light">
                            <b-tr>
                                <b-th width="50px">№</b-th>
                                <b-th>Наименование товара (работ, услуг)</b-th>
                                <b-th>Характеристики</b-th>
                                <b-th width="100px">Ед.изм</b-th>
                                <b-th width="100px">Количество</b-th>
                                <b-th width="100px">Цена за ед.</b-th>
                                <b-th width="100px">Валюта</b-th>
                                <b-th width="100px">Стоимость</b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody>
                            <b-tr v-for="(item, index) in purchase.deliverables" :key="item.id" v-bind="item">
                                <b-td>{{ index + 1 }}</b-td>
                                <b-td>{{ item.name }}</b-td>
                                <b-td>{{ item.characteristics }}</b-td>
                                <b-td style="text-align: right">{{ item.okei.local_symbol }}</b-td>
                                <b-td style="text-align: right" nowrap>{{ item.quantity }}</b-td>
                                <b-td style="text-align: right" nowrap>{{ $formatPrice(item.price_per_unit) }}</b-td>
                                <b-td>{{ purchase.currency_title }}</b-td>
                                <b-td style="text-align: right" nowrap>{{ $formatPrice(item.quantity * item.price_per_unit) }}</b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>

                    <template v-if="purchase.envelopes_opening_protocol">
                        <text-header>Протокол вскрытия конвертов № {{ purchase.envelopes_opening_protocol.reg_number }}</text-header>
                        <text-row label="Состав комиссии" value=" " />
                        <b-table-simple responsive>
                            <b-thead head-variant="light">
                                <b-tr>
                                    <b-th>ФИО</b-th>
                                    <b-th>Должность</b-th>
                                </b-tr>
                            </b-thead>
                            <b-tbody>
                                <b-tr v-for="(item, index) in purchase.envelopes_opening_protocol.commission" :key="index" v-bind="item">
                                    <b-td>{{ item.name }}</b-td>
                                    <b-td>{{ item.position }}</b-td>
                                </b-tr>
                            </b-tbody>
                        </b-table-simple>

                        <text-row-document label="Подписанный членами комиссии протокол вскрытия конвертов" v-model="purchase.envelopes_opening_protocol.attachment" />
                    </template>

                    <template v-if="purchase.final_protocol">
                        <text-header>Протокол процесса закупки № {{ purchase.final_protocol.reg_number }}</text-header>
                        <text-row label="Состав комиссии" value=" " />
                        <b-table-simple responsive>
                            <b-thead head-variant="light">
                                <b-tr>
                                    <b-th>ФИО</b-th>
                                    <b-th>Должность</b-th>
                                </b-tr>
                            </b-thead>
                            <b-tbody>
                                <b-tr v-for="(item, index) in purchase.final_protocol.commission" :key="index" v-bind="item">
                                    <b-td>{{ item.name }}</b-td>
                                    <b-td>{{ item.position }}</b-td>
                                </b-tr>
                            </b-tbody>
                        </b-table-simple>

                        <text-row-document label="Подписанный членами комиссии протокол процесса закупки" v-model="purchase.final_protocol.attachment" />
                    </template>
                </b-card>
            </b-tab>
            <b-tab title="Конкурсные заявки" v-if="purchase.proposals">
                <b-card>
                    <div class="table-responsive">
                        <b-table :items="purchase.proposals" :fields="proposalsFields" show-empty>
                            <template v-slot:empty>
                                <h5 style="text-align: center">Нет данных</h5>
                            </template>
                            <template v-slot:cell(status)="row">
                                <ul>
                                    <li><strong>Статус:</strong> {{ row.item.status.title }}</li>
                                    <li v-if="row.item.rejection_reason"><strong>Причина отклонения:</strong> {{ row.item.rejection_reason }}</li>
                                </ul>
                            </template>
                            <template v-slot:cell(supplier)="row">
                                <router-link :to="{ name: 'OrganizationEdit', params: { id: row.item.supplier.id } }" target="_blank">{{ row.item.supplier.name }}</router-link>
                            </template>
                            <template v-slot:cell(price)="data">
                                {{ $formatPrice(data.value) }}
                            </template>
                            <template v-slot:cell(created_at)="data">
                                {{ getDateTimeFormatSeconds(data.value) }}
                            </template>
                        </b-table>
                    </div>
                </b-card>
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
import TextHeader from '@/elements/text-header';
import TextRowDatetime from '@/elements/text-row-datetime';
import TextRowDocument from '@/elements/text-row-document.vue';

export default {
    name: 'direct-purchase-show',
    metaInfo: {
        title: 'Прямые закупки',
    },
    components: { TextRowDocument, TextRowDatetime, TextHeader },
    data() {
        return {
            purchase: null,
            loading: true,
            proposalsFields: [
                { key: 'created_at', label: 'Дата/время' },
                { key: 'supplier', label: 'Поставщик' },
                { key: 'price', label: 'Предложение', tdClass: 'table-money-column' },
                { key: 'status', label: 'Статус' },
            ],
        };
    },
    mounted() {
        this.fillData().then(() => {
            setTimeout(() => {
                this.loading = false;
            }, 100);
        });
    },
    methods: {
        async fillData() {
            this.purchase = await this.$api.directPurchases.get(this.$route.params.id);
        },
    },
};
</script>

<style scoped></style>
