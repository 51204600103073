<template>
    <div>
        <loading-card v-show="loading"></loading-card>
        <b-card v-show="!loading" no-body>
            <filter-block>
                <template #header-left>
                    <span class="text-large font-weight-bold">Простые закупки - Список закупок</span>
                </template>
                <template #header-right>
                    <export-simple-purchases-button :filter="filter" />
                </template>
                <template #filters>
                    <filter-date-range label="Дата публикации" v-model="filter.purchase_start" @input="onFilterChange" as-row :disabled="loading" />
                    <filter-text label="№ закупки" placeholder="Введите № закупки" v-model="filter.reg_number" @input="onFilterChange" :disabled="loading" />
                    <filter-select-multiple-with-all-button
                        v-model="filter.deliverable_groups"
                        label="Предмет закупки"
                        description="Не применяется к аналитическим выгрузкам"
                        :options="$globalDictionaries.deliverableGroups"
                        placeholder="Выберите предмет закупки"
                        :disabled="loading"
                        @input="onFilterChange" />
                    <filter-text label="Заказчик" placeholder="Введите название организации" v-model="filter.customer" @input="onFilterChange" :disabled="loading" />
                    <filter-select-multiple label="Статус" placeholder="Выберите статус" v-model="filter.status" :options="$globalDictionaries.purchaseStatuses" label-field="title" @input="onFilterChange" :disabled="loading" />
                </template>
            </filter-block>
            <b-card-body>
                <div class="table-responsive">
                    <vuetable
                        ref="vuetable"
                        :fields="columns"
                        :per-page="perPage"
                        :css="vuetable2Config.table"
                        :sort-order="paginationInfo.sortOrder"
                        :load-on-start="false"
                        :query-params="tableQueryParams"
                        :append-params="filter"
                        :http-fetch="fetchData"
                        @vuetable:pagination-data="onPaginationData"
                        api-url="/simple_purchases"
                        data-path="data"
                        pagination-path="meta"
                        no-data-template="Нет данных">
                        <template #reg_number="props">
                            <router-link :to="{ name: 'SimplePurchaseShow', params: { id: props.rowData.id } }" target="_blank">
                                {{ props.rowData.reg_number }}
                            </router-link>
                        </template>
                        <template #purchase_start_datetime="props">
                            {{ getDateTimeFormatSeconds(props.rowData.purchase_start_datetime) ?? '&mdash;' }}
                        </template>
                        <template #purchase_end_datetime="props">
                            {{ getDateTimeFormatSeconds(props.rowData.purchase_end_datetime) ?? '&mdash;' }}
                        </template>
                        <template #customer="props">
                            <router-link :to="{ name: 'OrganizationEdit', params: { id: props.rowData.customer.id } }" target="_blank">{{ props.rowData.customer.name }}</router-link>
                        </template>
                        <template #name="props">
                            {{ props.rowData.name ?? '&mdash;' }}
                        </template>
                        <template #status="props">
                            {{ props.rowData.status.title ?? '&mdash;' }}
                        </template>
                        <template #start_price="props">
                            {{ $formatPrice(props.rowData.start_price) }}<br />
                            {{ props.rowData.currency_title ?? '&mdash;' }}
                        </template>
                    </vuetable>
                </div>
                <div style="margin-top: 10px" class="float-right d-flex">
                    <vuetable-pagination ref="pagination" :css="vuetable2Config.pagination" @vuetable-pagination:change-page="onChangePage" />
                </div>
            </b-card-body>
        </b-card>
    </div>
</template>

<script>
import FilterBlock from '@/elements/filter-block.vue';
import ExportSimplePurchasesButton from '@/components/simple-purchases/export-simple-purchases-button.vue';

export default {
    name: 'simple-purchases-list',
    components: { ExportSimplePurchasesButton, FilterBlock },
    metaInfo: {
        title: 'Простые закупки - Список закупок',
    },
    data() {
        return {
            columns: [
                { name: 'id', sortField: 'id', title: '#', width: '40px' },
                { name: 'reg_number', sortField: 'reg_number', title: 'Регистрационный номер' },
                { name: 'purchase_start_datetime', sortField: 'purchase_start_datetime', title: 'Дата публикации', width: '100px' },
                { name: 'purchase_end_datetime', sortField: 'purchase_end_datetime', title: 'Дата окончания приема предложений', width: '100px' },
                { name: 'customer', title: 'Заказчик' },
                { name: 'name', sortField: 'name', title: 'Наименование конкурса' },
                { name: 'deliverable_group_title', sortField: 'deliverable_group_id', title: 'Предмет закупки' },
                { name: 'status', sortField: 'status', title: 'Статус' },
                { name: 'start_price', sortField: 'start_price', title: 'Сумма закупки, валюта', dataClass: 'text-right text-nowrap' },
                // { name: 'actions', title: 'Действия', width: '100px' },
            ],
            loading: true,
            data: [],
            isFiltersVisible: false,
            filter: this.$store.state.simplePurchases.filter,
            paginationInfo: this.$store.state.simplePurchases.pagination,
            perPage: 20,
            exportLoading: false,
        };
    },
    mounted() {
        this.fillData();
    },
    methods: {
        async fillData() {
            this.loading = true;

            this.$refs.vuetable.refresh().then(() => {
                setTimeout(() => {
                    this.loading = false;
                }, 100);
            });
        },
        fetchData(apiUrl, httpOptions) {
            return this.$http.get(apiUrl, httpOptions);
        },
        tableQueryParams(sortOrder, currentPage, perPage) {
            this.$store.commit('simplePurchases/updatePagination', this.paginationInfo);
            return {
                sortField: sortOrder[0].sortField,
                sortOrder: sortOrder[0].direction,
                page: currentPage,
                per_page: perPage,
            };
        },
        onPaginationData(paginationData) {
            this.$refs.pagination.setPaginationData(paginationData);
        },
        onChangePage(page) {
            this.paginationInfo.page = page;
            this.$refs.vuetable.changePage(page);
        },
        onFilterChange() {
            this.$store.commit('simplePurchases/updateFilter', this.filter);
            this.fillData();
        },
        // downloadExport() {
        //     this.exportLoading = true;
        //     Purchases.getXlsExport(this.filter)
        //         .then((response) => {
        //             FileDownload(response.data, 'Извещения_' + this.filter.purchase_start.from + '_' + this.filter.purchase_start.to + '.xlsx');
        //         })
        //         .catch((error) => {
        //             this.showError(error);
        //         })
        //         .finally(() => {
        //             this.exportLoading = false;
        //         });
        // },
    },
};
</script>
