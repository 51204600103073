<template>
    <b-button v-if="userRoleIsPermitted([AdminRole.SUPER])" :disabled="exportLoading" class="my-1 mx-1" size="sm" variant="primary" @click="downloadExport">Выгрузить в .XLSX (согласно фильтра) </b-button>
</template>
<script>
export default {
    name: 'export-direct-purchases-button',
    props: {
        filter: {},
    },
    data() {
        return {
            exportLoading: false,
        };
    },
    methods: {
        async downloadExport() {
            this.exportLoading = true;
            await this.$api.directPurchases.getXlsExport({
                purchase_start: this.filter.purchase_start,
            });
            this.exportLoading = false;
        },
    },
};
</script>
